import { createApp } from "vue";
//import { Field, Form } from "vee-validate";
import App from "./App.vue";
import router from "./router";
import * as dayjs from "dayjs";
import "bootstrap/scss/bootstrap.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap";
//import 'bootstrap-icons';

//new stuff
import store from "./_store";

//import store from './store'

let app = createApp(App);

// General
app.config.globalProperties.CONVENTION_YEAR = "2024";

// Registration
app.config.globalProperties.FIRST_DAY_OF_CONVENTION = "2024-07-19";
app.config.globalProperties.LAST_DAY_OF_CONVENTION = "2024-07-21";
app.config.globalProperties.TWENTYONE_CUTOFF = "2003-07-18";
app.config.globalProperties.EIGHTEEN_CUTOFF = "2006-07-19";
app.config.globalProperties.SIXTEEN_CUTOFF = "2008-07-19";
app.config.globalProperties.THIRTEEN_CUTOFF = "2011-07-19";
app.config.globalProperties.ONEDAY_REGISTRATION_PRICE = 30.0;
app.config.globalProperties.TWODAY_REGISTRATION_PRICE = 50.0;
app.config.globalProperties.BASIC_REGISTRATION_PRICE = 65.0;
app.config.globalProperties.ULTIMATE_REGISTRATION_PRICE = 120.0;
app.config.globalProperties.ALPHA_REGISTRATION_PRICE = 250.0;
app.config.globalProperties.EB_BASIC_REGISTRATION_PRICE = 60.0;
app.config.globalProperties.EB_ULTIMATE_REGISTRATION_PRICE = 110.0;
app.config.globalProperties.EB_ALPHA_REGISTRATION_PRICE = 250.0;
app.config.globalProperties.EB_CUTOFF_DATE = "2023-12-16";
app.config.globalProperties.LAGOON_PRICE = 75.03;
app.config.globalProperties.LAGOON_FULL_PRICE = 105.05;
app.config.globalProperties.LAGOON_DATE = "2024-07-18";
app.config.globalProperties.PROCESSING_FEES = 2.0;
app.config.globalProperties.VOLUNTEER_OPEN = "January";

// Alpha Info
app.config.globalProperties.ALPHA_DINNER = "Saturday, July 20";
app.config.globalProperties.ALPHA_DINNER_TIME = "7:00 PM";
app.config.globalProperties.ALPHA_DINNER_PRICE = 50;
app.config.globalProperties.AD_DEADLINE = "July 5";
app.config.globalProperties.AD_EXTRATICKET_DEADLINE = "2024-07-20";

// Dealer Apps
app.config.globalProperties.SINGLE_TABLE_PRICE = 75.0;
app.config.globalProperties.DOUBLE_TABLE_PRICE = 125.0;
app.config.globalProperties.DEALER_CLOSING_DATE = "2023-12-29";

// Hotel
app.config.globalProperties.HOTEL_LOTTERY_CLOSING = "2024-02-19";

// Charity
app.config.globalProperties.CHARITY_OPEN = "June";
app.config.globalProperties.CHARITY_APP_CLOSE = "Saturday, July 20";

// Dance Competition
app.config.globalProperties.DANCE_APP_OPEN = "May";
app.config.globalProperties.DANCE_APP_CLOSE = "Friday, June 21";
app.config.globalProperties.DANCE_APP_DETERMINATION = "Monday, June 24";

// Music Festival
app.config.globalProperties.FESTIVAL_APP_OPEN = "May";
app.config.globalProperties.FESTIVAL_APP_CLOSE = "Friday, May 24";
app.config.globalProperties.FESTIVAL_APP_DETERMINATION = "June 1";

// DJ Apps
app.config.globalProperties.DJ_APP_OPEN = "March";
app.config.globalProperties.DJ_APP_CLOSE = "Saturday, April 20";
app.config.globalProperties.DJ_APP_DETERMINATION = "June 1";

app.use(store).use(router).mount("#app");
